<!-- qa问题搜索有结果 -->
<template>
  <div>
    <headBar />
    <searchModel />
    <div class="backGroundBg">
      <div class="con">
        <div class="backHomg">Centro de ayuda</div>
        <div class="searchConBox">
          <leftMenu />
          <div class="searchBoxRight">
            <h1>Resultados para: {{ $route.query.s.replace("+", " ") }}</h1>
            <div class="divider"></div>
            <ul class="answerList">
              <li
                v-for="(searchItem, searchIndex) in showList"
                :key="searchIndex"
              >
                <h2>
                  <span
                    @click="$fun.routerToPage(searchItem.router)"
                    v-html="searchItem.title"
                  ></span>
                </h2>
                <p><span v-html="searchItem.answer"></span>[…]</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="pageBackground" v-if="showList.length">
      <ul class="answerList">
        <li v-for="(searchItem, searchIndex) in showList" :key="searchIndex">
          <h2>
            <span
              @click="$fun.routerToPage(searchItem.router)"
              v-html="searchItem.title"
            ></span>
          </h2>
          <p><span v-html="searchItem.answer"></span>[…]</p>
        </li>
      </ul>
      <div class="pageControl">
        <span
          v-if="Math.ceil(this.searchArr.length / 10) != page"
          @click="changePage('+')"
          >← {{ $fanyi("以前") }}</span
        >
        <span v-if="page != 1" @click="changePage('-')"
          >{{ $fanyi("以下") }} →</span
        >
      </div>
    </div>
    -->
    <contactUs />
    <footbar />
  </div>
</template>
<script>
import headBar from "../../../components/head/HomePageTop.vue";
import leftMenu from "./components/leftMenu.vue";
import footbar from "../../../components/foot/Foot.vue";
import searchModel from "./components/searchModel.vue";
import pageData from "./unitl/pageData";
import contactUs from "./components/contactUs.vue";
export default {
  data() {
    return {
      pageData,
      page: 1,
      searchArr: [],
      showList: [],
    };
  },
  components: {
    headBar,
    footbar,
    searchModel,
    leftMenu,
    contactUs,
  },
  computed: {},
  created() {},
  mounted() {
    this.getSearchList();
  },
  methods: {
    // 翻页
    changePage(type) {
      if (type == "+") {
        this.page = Math.min(
          this.page + 1,
          Math.ceil(this.searchArr.length / 10)
        );
      } else {
        this.page = Math.max(this.page - 1, 1);
      }
      this.showList = this.searchArr.slice(this.page * 10 - 10, this.page * 10);
    },
    // 获取数据
    getSearchList() {
      if (this.$route.query.s) {
        let str = this.$route.query.s;
        // 把用户搜索字符串以加号为分割成数组
        let strArr = str.split("+");
        let searchArr = []; //搜索结果列表
        // 用用户搜索字符串数组到数据库里比对,如果title、answer、content有一项包含搜索字符串就提取出来
        pageData.forEach((pageItem) => {
          strArr.forEach((strItem) => {
            if (
              (pageItem.title.indexOf(strItem) != -1 ||
                pageItem.answer.indexOf(strItem) != -1 ||
                pageItem.content.indexOf(strItem) != -1) &&
              pageItem.menuLevel != 1 //利用qa首页的menuLevel把qa首页排除出搜索结果
            ) {
              searchArr.push(pageItem);
            }
          });
        });
        this.searchArr = searchArr;
        // 一页显示10个
        // 2023/3/27 新设计图上没有显示需要分页功能,先不限制显示多少个
        // this.showList = searchArr.slice(0, 10);
        this.showList = searchArr;
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.backGroundBg {
  background-color: white;
  padding-top: 20px;
  .con {
    width: 1000px;
    margin: 0 auto;
    .backHomg {
      font-size: 14px;
      color: #7a7a7a;
      padding: 10px 10px 24px;
    }
    .searchConBox {
      display: flex;
      .searchBoxRight {
        flex: 1;
        padding: 10px;
        h1 {
          font-size: 26px;
          font-weight: 500;
          line-height: 1;
          margin-bottom: 20px;
        }
        // 分割线
        .divider {
          height: 1px;
          background-color: #d5d5d5;
          margin-bottom: 20px;
        }
        .answerList {
          h2 {
            margin-bottom: 20px;
            /deep/span {
              font-weight: 500;
              line-height: 1;
              color: inherit;
              font-size: 26px;
              cursor: pointer;
              color: #1a289d;
              &:hover {
                color: black;
              }
            }
          }
          p,
          /deep/p {
            margin-bottom: 30px;
            font-size: 15px;
            line-height: 1.8em;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            * {
              display: inline;
            }
            b {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
</style>
